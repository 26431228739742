@use "sass:string"; // Must be at the top of the file
@use './variables.scss'; // Import variables

/** Generate cursor classes both `cursor-grab` and `cursor-[grab]` styles */
/** Generate cursor classes dynamically */
@each $cursor in $cursors {

    /** Standard class like "cursor-grab" */
    .cursor-#{string.unquote($cursor)} {
        cursor: $cursor !important;
    }

    /** Bracket notation like "cursor-[grab]" */
    .cursor-\[#{string.unquote($cursor)}\] {
        cursor: $cursor !important;
    }
}

/* Additional helper classes */
.clickable,
.pointer {
    @extend .cursor-pointer;
}

.clickableNone,
.default {
    @extend .cursor-default;
}

/* Overflow */
.overflow-hidden,
.overflow-none {
    overflow: hidden !important
}

.overflowX-hidden,
.overflowX-none {
    overflow-x: hidden !important
}

.overflowY-hidden,
.overflowY-none {
    overflow-y: hidden !important
}

.overflow {
    overflow: auto
}

.overflowX {
    overflow-x: auto !important;
    overflow-y: hidden !important;
}

.overflowY {
    overflow-y: auto !important;
    overflow-x: hidden !important;
}

.pointerEvents-none {
    pointer-events: none !important;
}

.pointerEvents-all {
    pointer-events: all !important;
}

/** Image */
.objectFit-cover {
    object-fit: cover !important;
}

.br-8 {
    border-radius: 8px !important;
}

.br-0 {
    border-radius: 0px !important;
}

/** Width */
.w-100p {
    width: 100% !important;
}

.w-50p {
    width: 50% !important;
}

.w-50px {
    width: 50px !important;
}

.max-w-246px {
    max-width: 246px !important;
}

.w-150px {
    width: 150px !important;
}

.w-150px {
    width: 150px !important;
}

.w-160px {
    width: 160px !important;
}

/** Height */
.h-100p {
    height: 100% !important;
}

.h-50p {
    height: 50% !important;
}

.h-50px {
    height: 50px !important;
}

.h-100px {
    height: 100px !important;
}

/** Border */
.b-none {
    border: none !important;
}

.b-0 {
    border: 0 !important;
}

.HW-20 {
    height: 20px !important;
    width: 20px !important;
}

/** Opacity */
.opacity-01 {
    opacity: 0.10 !important;
}

.opacity-02 {
    opacity: 0.20 !important;
}

.opacity-25 {
    opacity: 0.25 !important;
}

.opacity-03 {
    opacity: 0.30 !important;
}

.opacity-04 {
    opacity: 0.40 !important;
}

.opacity-05,
.opacity-50 {
    opacity: 0.50 !important;
}

.opacity-06 {
    opacity: 0.60 !important;
}

.opacity-07 {
    opacity: 0.70 !important;
}

.opacity-75 {
    opacity: 0.75 !important;
}

.opacity-08 {
    opacity: 0.80 !important;
}

.opacity-09 {
    opacity: 0.90 !important;
}

.opacity-1,
.opacity-100 {
    opacity: 1 !important;
}

.disabled-box {
    -webkit-user-select: none;
    pointer-events: none; // Disable interactions
    user-select: none; //Prevent text selection
    opacity: 0.5; // Optional: Reduce opacity further for better visual effect
}